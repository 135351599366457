import { useMe } from '@2ndmarket/services';

import { Login } from '../sections';
import { AuthSEO, Loading } from '../components';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const LoginPage = () => {
  const { loading, error } = useMe();
  const router = useRouter();

  useEffect(() => {
    if (!error) {
      router.push('/escolher-produto');
    }
  }, [error, router]);

  return (
    <>
      <AuthSEO
        title="Faça login na sua conta"
        description="Faça login ou crie sua conta 2ND Market e tenha acesso a revolução financeira digital."
      />
      {loading && <Loading loading={loading} />}
      {!loading && error && <Login />}
    </>
  );
};

export default LoginPage;
